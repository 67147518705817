import React from 'react';

const Error = () => {
    return (
        <>
            <div className="container_error">
                <h1>PAGE NOT FOUND SORRY 😭</h1>
            </div>
        </>
    );
};

export default Error;